import { graphql } from 'gatsby';
import React, { useContext } from 'react';
import { AppContext } from '../context';
import DefaultPage from '../templates/default';

export default function fourOhFourPage(props) {
  let locale = 'en';
  if (props?.path?.includes('/ja/')) {
    locale = 'ja';
  }
  if (props?.path?.includes('/zh-hans/')) {
    locale = 'zh-hans';
  }

  const ctx = useContext(AppContext);

  if (locale === 'en' || ctx.locale === 'en') {
    return <DefaultPage {...props} />;
  }

  if (locale === 'ja' || ctx.locale === 'ja') {
    props.data.wpPage = props.data.ja;
    return <DefaultPage {...props} />;
  }

  if (locale === 'zh-hans' || ctx.locale === 'zh-hans') {
    props.data.wpPage = props.data.zhHans;
    return <DefaultPage {...props} />;
  }

}

export const pageQuery = graphql`
  query FourOhFour {
    wpPage(id: { eq: "cG9zdDoxNDY5" }) {
      ...SEO
      ...GeneratedWpPage
    }
    ja: wpPage(id: { eq: "cG9zdDoyMDMxOA==" }) {
      ...SEO
      ...GeneratedWpPage
    }
    zhHans: wpPage(id: { eq: "cG9zdDoyMDMzMg==" }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
  }
`;
